<template>
  <div>
    <div class="wrapper mx-auto">
      <b-link to="/">
        <img
          class="mx-auto d-block mt-4"
          src="../assets/logo_darkblue.png"
          width="75px"
          height="75px"
      /></b-link>
    </div>
    <p class="text-center h4 mt-2">Sign in to SaferCloud</p>
    <b-card class="mx-auto mt-4" style="max-width: 22rem" bg-variant="light">
      <b-form @submit.prevent="login">
        <label>Email</label>
        <b-form-input v-model="email" required type="email" />
        <div class="d-flex justify-content-between mt-3">
          <div>
            <label class="text-right">Password</label>
          </div>
          <div>
            <b-link to="/password/reset" class="text-right"
              >Forgot Password?</b-link
            >
          </div>
        </div>

        <b-form-input v-model="password" required type="password" />
        <b-button
          type="submit"
          style="width: 100%"
          class="btn-block mt-3"
          variant="success"
          >Sign In</b-button
        >
      </b-form>
      <b-overlay :show="loading" no-wrap></b-overlay>
    </b-card>
    <b-card class="mx-auto text-center mt-4" style="max-width: 22rem">
      <b-card-text>
        New to SaferCloud?
        <b-link to="/register"> Register for an account </b-link>
      </b-card-text>
    </b-card>
    <MiniFooter class="mt-5" />
  </div>
</template>

<script>
import MiniFooter from "@/components/footers/Mini.vue";
import RequestService from "@/services/RequestService";

export default {
  name: "Login",
  components: {
    MiniFooter,
  },
  data() {
    return {
      email: "",
      password: "",
      loading: false,
    };
  },
  created() {
    if (this.$route.query.passwordUpdate)
      this.makeToast(
        "Your password was updated successfully, you can now login",
        "Password Updated",
        "success"
      );
    if (this.$route.query.emailUpdate)
      this.makeToast(
        "Your email was updated successfully, you can now login",
        "Email Updated",
        "success"
      );
  },
  methods: {
    async login() {
      this.loading = true;
      try {
        const postLogin = await RequestService.postLogin(
          this.email,
          this.password
        );
        await this.$store.dispatch("auth/login", postLogin);
        if (this.$route.query.returnTo)
          this.$router.push(this.$route.query.returnTo);
        else this.$router.push("/dashboard");
      } catch (err) {
        if (err.message) this.makeToast(err.message, "Oh no", "danger");
      }
      this.loading = false;
    },
    makeToast(message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
