<template>
  <div>
    <div class="wrapper mx-auto">
      <b-link to="/">
        <img
          class="mx-auto d-block mt-4"
          src="../assets/logo_darkblue.png"
          width="75px"
          height="75px"
      /></b-link>
    </div>
    <p class="text-center h4 mt-2">Updating your email</p>
    <b-card
      class="mx-auto mt-4 text-center"
      style="max-width: 22rem"
      bg-variant="light"
    >
      <b-overlay :show="loading" no-wrap></b-overlay>
    </b-card>

    <MiniFooter class="my-5" />
  </div>
</template>

<script>
import MiniFooter from "@/components/footers/Mini.vue";
import RequestService from "@/services/RequestService";

export default {
  name: "EmailSet",
  components: {
    MiniFooter,
  },
  data() {
    return {
      resetEmailToken: "",
      email: "",
      newEmail: "",
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    this.resetEmailToken = this.$route.query.token;
    this.email = this.$route.query.email;
    this.newEmail = this.$route.query.newEmail;
    if (
      this.email === undefined ||
      this.newEmail === undefined ||
      this.resetEmailToken === undefined
    ) {
      this.makeToast("Something went wrong", "Oh no", "danger");
    } else {
      await this.updateEmail();
    }
    this.loading = false;
  },
  methods: {
    async updateEmail() {
      try {
        await RequestService.postEmailSet(
          this.resetEmailToken,
          this.email,
          this.newEmail
        );
        if (this.$store.state.auth.status.loggedIn)
          await this.$store.dispatch("auth/logout");
        this.$router.push("/login?emailUpdate=true");
      } catch (err) {
        if (err.message) this.makeToast(err.message, "Oh no", "danger");
      }
    },
    makeToast(message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
