<template>
  <div>
    <DashboardHeader organization="true" />
    <b-container fluid class="pt-5" style="max-width: 60rem">
      <b-row>
        <b-col md="auto">
          <OrganizationSidebar billing="true" />
        </b-col>
        <b-col class="px-5" v-if="!loading">
          <Checkout
            v-if="!$store.state.auth.user.permissions.includes('Paid')"
          />
          <Billing v-if="$store.state.auth.user.permissions.includes('Paid')" />
        </b-col>
        <b-col class="px-5 text-center" v-if="loading">
          Updating your account
          <b-overlay opacity="0" :show="loading" no-wrap></b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import DashboardHeader from "@/components/headers/Dashboard.vue";
import OrganizationSidebar from "@/components/sidebars/Organization.vue";
import Checkout from "@/components/settings/organization/billing/Checkout.vue";
import Billing from "@/components/settings/organization/billing/Billing.vue";
import RequestService from "@/services/RequestService";

export default {
  name: "OrganizationBilling",
  components: {
    DashboardHeader,
    OrganizationSidebar,
    Checkout,
    Billing,
  },
  data() {
    return {
      loading: false,
    };
  },
  async created() {
    if (this.$route.query.update) {
      this.loading = true;
      try {
        const postJWTUpdate = await RequestService.postJWTUpdate();
        await this.$store.dispatch("auth/update", postJWTUpdate);
        this.makeToast(
          "Your account was updated with your new subscription",
          "Success",
          "success"
        );
      } catch (err) {
        if (err.message) this.makeToast(err.message, "Oh no", "danger");
      }
      this.loading = false;
    }
    if (this.$route.query.cancel)
      this.makeToast(
        "Your purchase was canceled",
        "Purchase Canceled",
        "danger"
      );
  },
  methods: {
    makeToast(message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
