<template>
  <div>
    <h3>Profile</h3>
    <hr />
    <b-form @submit.prevent="updateProfile">
      <label>Name</label>
      <b-skeleton v-if="loading" type="input" width="20rem"></b-skeleton>
      <b-form-input
        v-if="!loading"
        v-model="name"
        class="mt-1"
        style="max-width: 20rem"
        required
      />
      <label class="mt-2">Job Title</label>
      <b-skeleton v-if="loading" type="input" width="20rem" />
      <b-form-input
        v-model="job"
        v-if="!loading"
        class="mt-1"
        style="max-width: 20rem"
        required
      />
      <b-button :disabled="loading" type="submit" variant="success" class="mt-3"
        >Update profile</b-button
      >
    </b-form>
  </div>
</template>
<script>
import RequestService from "@/services/RequestService";

export default {
  name: "Profile",
  data() {
    return {
      name: "",
      job: "",
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    await this.getProfile();
    this.loading = false;
  },
  methods: {
    async getProfile() {
      try {
        const getAccountProfile = await RequestService.getAccountProfile();
        this.name = getAccountProfile.name;
        this.job = getAccountProfile.job;
      } catch (err) {
        if (err.message) this.makeToast(err.message, "Oh no", "danger");
      }
      return Promise.resolve(true);
    },
    async updateProfile() {
      this.loading = true;
      try {
        await RequestService.patchAccountProfile(this.name, this.job);
        this.makeToast(
          "Your profile was updated successfully",
          "Profile Updated",
          "success"
        );
      } catch (err) {
        if (err.message) this.makeToast(err.message, "Oh no", "danger");
      }
      this.loading = false;
    },
    makeToast(message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
