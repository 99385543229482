<template>
  <div>
    <b-card no-body header="Organization Settings">
      <b-list-group flush>
        <b-link to="/settings/organization/profile"
          ><b-list-group-item :active="profile ? true : false"
            >Profile Settings</b-list-group-item
          ></b-link
        >
        <b-link to="/settings/organization/billing"
          ><b-list-group-item :active="billing ? true : false"
            >Billing &amp; Plans</b-list-group-item
          ></b-link
        >
        <b-link
          to="/settings/organization/integration"
          v-if="$store.state.auth.user.permissions.includes('Paid')"
          ><b-list-group-item :active="integration ? true : false"
            >Integrations</b-list-group-item
          ></b-link
        >
        <b-link
          to="/settings/organization/teacher"
          v-if="$store.state.auth.user.permissions.includes('Paid')"
          ><b-list-group-item :active="teacher ? true : false"
            >Teachers</b-list-group-item
          ></b-link
        >
      </b-list-group>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "OrganizationSidebar",
  props: ["profile", "billing", "integration", "teacher"],
};
</script>
