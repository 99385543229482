<template>
  <div>
    <DashboardHeader account="true" />
    <b-container fluid class="pt-5" style="max-width: 60rem">
      <b-row>
        <b-col md="auto">
          <AccountSidebar profile="true" />
        </b-col>
        <b-col class="px-5">
          <Profile />
          <Email />
          <Delete />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import DashboardHeader from "@/components/headers/Dashboard.vue";
import AccountSidebar from "@/components/sidebars/Account.vue";
import Profile from "@/components/settings/account/profile/Profile.vue";
import Email from "@/components/settings/account/profile/Email.vue";
import Delete from "@/components/settings/account/profile/Delete.vue";

export default {
  name: "AccountProfile",
  components: {
    DashboardHeader,
    AccountSidebar,
    Profile,
    Email,
    Delete,
  },
};
</script>
