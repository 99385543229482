<template>
  <div>
    <div class="wrapper mx-auto">
      <b-link to="/">
        <img
          class="mx-auto d-block mt-4"
          src="../assets/logo_darkblue.png"
          width="75px"
          height="75px"
      /></b-link>
    </div>
    <p class="text-center h4 mt-4">Register for SaferCloud</p>
    <b-card class="mx-auto mt-4" style="max-width: 35rem" bg-variant="light">
      <b-form @submit.prevent="register">
        <label>Email</label>
        <b-form-input v-model="email" required type="email" />
        <div class="d-flex justify-content-between mt-2">
          <div>
            <label>First Name</label>
            <b-form-input style="width: 15rem" required v-model="fname" />
          </div>
          <div>
            <label>Last Name</label>
            <b-form-input style="width: 15rem" required v-model="lname" />
          </div>
        </div>
        <label class="mt-2">Job Title</label>
        <b-form-input required v-model="job" />
        <label class="mt-2">Institution Name</label>
        <b-form-input required v-model="organization" />
        <label class="mt-2">Institution Location</label>
        <b-form-input required v-model="organizationLocation" />
        <label class="mt-3"><h5>Institution Type</h5></label>
        <b-form-radio-group required stacked>
          <b-form-radio v-model="organizationType" value="K-5"
            >Elementary School (K-5)</b-form-radio
          >
          <b-form-radio v-model="organizationType" value="6-8"
            >Middle School (6-8)</b-form-radio
          >
          <b-form-radio v-model="organizationType" value="9-12"
            >High School</b-form-radio
          >
          <b-form-radio v-model="organizationType" value="Higher Ed"
            >Higher Education</b-form-radio
          >
          <b-form-radio v-model="organizationType" value="Disctrict"
            >School District</b-form-radio
          >
          <b-form-radio v-model="organizationType" value="Other"
            >Other</b-form-radio
          >
        </b-form-radio-group>
        <b-form-input
          v-if="organizationType === 'Other'"
          placeholder="Please explain"
          v-model="organizationTypeOther"
          required
        />
        <label class="mt-2"><h5>Use Case</h5></label>
        <b-form-radio-group required stacked>
          <b-form-radio v-model="useCase" value="Integration"
            >Interested In Integrating</b-form-radio
          >
          <b-form-radio v-model="useCase" value="Demo"
            >Interested In A Demo</b-form-radio
          >
          <b-form-radio v-model="useCase" value="Quote"
            >Looking For A Quote</b-form-radio
          >
          <b-form-radio v-model="useCase" value="Info"
            >Looking For More Information</b-form-radio
          >
          <b-form-radio v-model="useCase" value="Other">Other</b-form-radio>
        </b-form-radio-group>
        <b-form-input
          v-if="useCase === 'Other'"
          placeholder="Please explain"
          v-model="useCaseOther"
          required
        />
        <hr />
        <b-form-checkbox v-model="marketingEmails"
          >I want to receive promotional and marketing emails from
          SaferCloud</b-form-checkbox
        >
        <b-form-checkbox class="mt-2" required
          >I have read and agree to SaferCloud's
          <b-link to="/terms">Terms</b-link>
          and
          <b-link to="/privacy">Privacy Policy</b-link></b-form-checkbox
        >
        <b-button
          type="submit"
          style="width: 100%"
          class="btn-block mt-3"
          variant="success"
          >Register</b-button
        >
      </b-form>
      <b-overlay :show="loading" no-wrap></b-overlay>
    </b-card>

    <MiniFooter class="my-5" />
  </div>
</template>

<script>
import MiniFooter from "@/components/footers/Mini.vue";
import RequestService from "@/services/RequestService";

export default {
  name: "Register",
  components: {
    MiniFooter,
  },
  data() {
    return {
      organization: "",
      organizationType: "",
      organizationTypeOther: "",
      useCase: "",
      useCaseOther: "",
      fname: "",
      lname: "",
      email: "",
      job: "",
      marketingEmails: false,
      organizationLocation: "",
      loading: false,
    };
  },
  methods: {
    async register() {
      this.loading = true;
      const name = `${this.fname} ${this.lname}`;
      let organizationType = this.organizationType;
      let useCase = this.useCase;
      if (organizationType === "Other")
        organizationType = this.organizationTypeOther;
      if (useCase === "Other") useCase = this.useCaseOther;
      try {
        await RequestService.postRegister(
          this.email,
          name,
          this.job,
          this.organization,
          this.organizationLocation,
          organizationType,
          useCase,
          this.marketingEmails
        );
        this.$router.push("/?register=true");
      } catch (err) {
        if (err.message) this.makeToast(err.message, "Oh no", "danger");
      }
      this.loading = false;
    },
    makeToast(message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
