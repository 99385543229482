import axios from "axios";
import GetJWTHeader from "./GetJWTHeader";

const API_URL = process.env.VUE_APP_API_URL;

class RequestService {
  static genericGet() {
    return new Promise((resolve, reject) => {
      axios
        .get(API_URL + "", { headers: GetJWTHeader() })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static genericPost() {
    return new Promise((resolve, reject) => {
      axios
        .post(API_URL + "", {}, { headers: GetJWTHeader() })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static genericPatch() {
    return new Promise((resolve, reject) => {
      axios
        .patch(API_URL + "", {}, { headers: GetJWTHeader() })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static genericDelete() {
    return new Promise((resolve, reject) => {
      axios
        .delete(API_URL + "", { headers: GetJWTHeader(), data: {} })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static getAccountNotificationsEmail(timestamp = false) {
    return new Promise((resolve, reject) => {
      let url = API_URL + "/account/notifications/email";
      if (timestamp)
        url =
          API_URL +
          "/account/notifications/email?timestamp=" +
          new Date().getTime();
      axios
        .get(url, {
          headers: GetJWTHeader(),
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static patchAccountNotificationsEmail(marketingEmails) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          API_URL + "/account/notifications/email",
          {
            marketingEmails,
          },
          { headers: GetJWTHeader() }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static getAccountProfile(timestamp = false) {
    return new Promise((resolve, reject) => {
      let url = API_URL + "/account/profile";
      if (timestamp)
        url = API_URL + "/account/profile?timestamp=" + new Date().getTime();
      axios
        .get(url, { headers: GetJWTHeader() })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static patchAccountProfile(name, job) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          API_URL + "/account/profile",
          {
            name,
            job,
          },
          { headers: GetJWTHeader() }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static patchAccountProfileDelete() {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          API_URL + "/account/profile/delete",
          {},
          { headers: GetJWTHeader() }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static getAccountProfileEmail(timestamp = false) {
    return new Promise((resolve, reject) => {
      let url = API_URL + "/account/profile/email";
      if (timestamp)
        url =
          API_URL + "/account/profile/email?timestamp=" + new Date().getTime();
      axios
        .get(url, { headers: GetJWTHeader() })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static patchAccountProfileEmail(email) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          API_URL + "/account/profile/email",
          { email },
          { headers: GetJWTHeader() }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static patchAccountProfileEmailCancel() {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          API_URL + "/account/profile/email/cancel",
          {},
          { headers: GetJWTHeader() }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static patchAccountProfileEmailResend() {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          API_URL + "/account/profile/email/resend",
          {},
          { headers: GetJWTHeader() }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static patchAccountSecurityPassword(oldPassword, password, confirmPassword) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          API_URL + "/account/security/password",
          {
            oldPassword,
            password,
            confirmPassword,
          },
          { headers: GetJWTHeader() }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static getApproval(timestamp = false) {
    return new Promise((resolve, reject) => {
      let url = API_URL + "/approval";
      if (timestamp)
        url = API_URL + "/approval?timestamp=" + new Date().getTime();
      axios
        .get(url, { headers: GetJWTHeader() })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static postApproval(email, approval) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          API_URL + "/approval",
          {
            email,
            approval,
          },
          { headers: GetJWTHeader() }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static postBilling() {
    return new Promise((resolve, reject) => {
      axios
        .post(API_URL + "/billing", {}, { headers: GetJWTHeader() })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static postCheckout(quantity) {
    return new Promise((resolve, reject) => {
      axios
        .post(API_URL + "/checkout", { quantity }, { headers: GetJWTHeader() })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static postEmailSet(resetEmailToken, email, newEmail) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          API_URL + "/email/set",
          {
            resetEmailToken,
            email,
            newEmail,
          },
          {}
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static getIntegration(timestamp = false) {
    return new Promise((resolve, reject) => {
      let url = API_URL + "/integration";
      if (timestamp)
        url = API_URL + "/integration?timestamp=" + new Date().getTime();
      axios
        .get(url, { headers: GetJWTHeader() })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static postIntegration(
    url,
    clientId,
    name,
    authenticationEndpoint,
    accesstokenEndpoint,
    license,
    method,
    keySetURL,
    kty,
    crv,
    x,
    y,
    kid,
    rsa
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          API_URL + "/integration",
          {
            url,
            clientId,
            name,
            authenticationEndpoint,
            accesstokenEndpoint,
            license,
            method,
            keySetURL,
            kty,
            crv,
            x,
            y,
            kid,
            rsa,
          },
          { headers: GetJWTHeader() }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static patchIntegration(
    url,
    clientId,
    name,
    authenticationEndpoint,
    accesstokenEndpoint,
    integrationID,
    method,
    keySetURL,
    kty,
    crv,
    x,
    y,
    kid,
    rsa
  ) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          API_URL + "/integration",
          {
            url,
            clientId,
            name,
            authenticationEndpoint,
            accesstokenEndpoint,
            integrationID,
            method,
            keySetURL,
            kty,
            crv,
            x,
            y,
            kid,
            rsa,
          },
          { headers: GetJWTHeader() }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static deleteIntegration(url, clientId, integrationID) {
    return new Promise((resolve, reject) => {
      axios
        .delete(API_URL + "/integration", {
          headers: GetJWTHeader(),
          data: {
            url,
            clientId,
            integrationID,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static getIntegrationJWT(timestamp = false) {
    return new Promise((resolve, reject) => {
      let url = API_URL + "/integration/jwt";
      if (timestamp)
        url = API_URL + "/integration/jwt?timestamp=" + new Date().getTime();
      axios
        .get(url, { headers: GetJWTHeader() })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static postJWTUpdate() {
    return new Promise((resolve, reject) => {
      axios
        .post(API_URL + "/jwt/update", {}, { headers: GetJWTHeader() })
        .then((res) => {
          localStorage.setItem("user", JSON.stringify(res.data));
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static postLogin(email, password) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          API_URL + "/login",
          {
            email,
            password,
          },
          {}
        )
        .then((res) => {
          localStorage.setItem("user", JSON.stringify(res.data));
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static getOrganizationProfile(timestamp = false) {
    return new Promise((resolve, reject) => {
      let url = API_URL + "/organization/profile";
      if (timestamp)
        url =
          API_URL + "/organization/profile?timestamp=" + new Date().getTime();
      axios
        .get(url, { headers: GetJWTHeader() })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static patchOrganizationProfile(
    organization,
    organizationLocation,
    organizationType
  ) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          API_URL + "/organization/profile",
          {
            organization,
            organizationLocation,
            organizationType,
          },
          { headers: GetJWTHeader() }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static postPasswordReset(email) {
    return new Promise((resolve, reject) => {
      axios
        .post(API_URL + "/password/reset", { email }, {})
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static postPasswordSet(password, confirmPassword, email, resetPassToken) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          API_URL + "/password/set",
          {
            password,
            confirmPassword,
            email,
            resetPassToken,
          },
          {}
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static getPaymentFailed(timestamp = false) {
    return new Promise((resolve, reject) => {
      let url = API_URL + "/payment/failed";
      if (timestamp)
        url = API_URL + "/payment/failed?timestamp=" + new Date().getTime();
      axios
        .get(url, { headers: GetJWTHeader() })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static postPaymentFailed() {
    return new Promise((resolve, reject) => {
      axios
        .post(API_URL + "/payment/failed", {}, { headers: GetJWTHeader() })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static postRegister(
    email,
    name,
    job,
    organization,
    organizationLocation,
    organizationType,
    useCase,
    marketingEmails
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          API_URL + "/register",
          {
            email,
            name,
            job,
            organization,
            organizationLocation,
            organizationType,
            useCase,
            marketingEmails,
          },
          {}
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static getStatistics(timestamp = false) {
    return new Promise((resolve, reject) => {
      let url = API_URL + "/statistics";
      if (timestamp)
        url = API_URL + "/statistics?timestamp=" + new Date().getTime();
      axios
        .get(url, { headers: GetJWTHeader() })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static getTeacher(timestamp = false) {
    return new Promise((resolve, reject) => {
      let url = API_URL + "/teacher";
      if (timestamp)
        url = API_URL + "/teacher?timestamp=" + new Date().getTime();
      axios
        .get(url, { headers: GetJWTHeader() })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static postTeacher(email, name, job, integrations) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          API_URL + "/teacher",
          {
            email,
            name,
            job,
            integrations,
          },
          { headers: GetJWTHeader() }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static patchTeacher(email, name, job, integrations, oldEmail) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          API_URL + "/teacher",
          {
            email,
            name,
            job,
            integrations,
            oldEmail,
          },
          { headers: GetJWTHeader() }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static patchTeacherCancel(oldEmail) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          API_URL + "/teacher/cancel",
          { oldEmail },
          { headers: GetJWTHeader() }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static patchTeacherResend(oldEmail) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          API_URL + "/teacher/resend",
          { oldEmail },
          { headers: GetJWTHeader() }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static deleteTeacher(email, integrations) {
    return new Promise((resolve, reject) => {
      axios
        .delete(API_URL + "/teacher", {
          headers: GetJWTHeader(),
          data: { email, integrations },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }
}

export default RequestService;
