<template>
  <div>
    <LandingHeader />
    <LandingContent />
    <LandingFooter />
  </div>
</template>

<script>
import LandingHeader from "@/components/headers/Landing";
import LandingFooter from "@/components/footers/Landing";
import LandingContent from "@/components/landing/Content";

export default {
  name: "Landing",
  components: {
    LandingHeader,
    LandingContent,
    LandingFooter,
  },
  async created() {
    if (this.$route.query.register)
      this.makeToast(
        "Check your inbox in 1-2 days for a response",
        "Registration Successful",
        "success"
      );
    if (this.$route.query.delete)
      this.makeToast(
        "Your account has been deleted",
        "Deletion Successful",
        "danger"
      );
    if (this.$route.query.logout)
      this.makeToast("You've been logged out", "Logged Out", "success");
  },
  methods: {
    makeToast(message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
