<template>
  <div>
    <div class="wrapper mx-auto">
      <b-link to="/">
        <img
          class="mx-auto d-block mt-4"
          src="../assets/logo_darkblue.png"
          width="75px"
          height="75px"
      /></b-link>
    </div>
    <p class="text-center h4 mt-2">Reset your password</p>
    <b-card class="mx-auto mt-4" style="max-width: 22rem" bg-variant="light">
      <b-form @submit.prevent="resetPass">
        <label
          >Enter your email and we will send you a password reset link</label
        >
        <b-form-input
          placeholder="Email address"
          required
          v-model="email"
          type="email"
        />
        <b-button
          type="submit"
          style="width: 100%"
          class="btn-block mt-3"
          variant="success"
          >Send a password reset email</b-button
        >
      </b-form>
      <b-overlay :show="loading" no-wrap></b-overlay>
    </b-card>

    <MiniFooter class="my-5" />
  </div>
</template>

<script>
import MiniFooter from "@/components/footers/Mini.vue";
import RequestService from "@/services/RequestService";

export default {
  name: "PasswordReset",
  components: {
    MiniFooter,
  },
  data() {
    return {
      email: "",
      loading: false,
    };
  },
  methods: {
    async resetPass() {
      this.loading = true;
      try {
        await RequestService.postPasswordReset(this.email);
        this.makeToast(
          "Check your inbox for a password reset email",
          "Email Sent",
          "success"
        );
      } catch (err) {
        if (err.message) this.makeToast(err.message, "Oh no", "danger");
      }
      this.loading = false;
    },
    makeToast(message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
