<template>
  <div>
    <h3 class="mt-5">Delete account</h3>
    <hr />
    <b-button @click="launchDelete" variant="danger"
      >Delete your account</b-button
    >
    <b-modal
      :visible="deleteModal"
      title="Delete Account"
      hide-footer
      @hide="closeDelete"
      body-class="position-static"
    >
      <b-form @submit.prevent="submitDelete">
        <div v-if="!detectedInts">
          <p>Are you sure you want to delete your account?</p>
          <label>Type 'delete' to continue</label>
          <b-form-input required placeholder="delete" v-model="confirmDelete" />
        </div>
        <p v-if="detectedInts">
          Your account cannot be deleted yet because you have integrations
          configured. Please remove those
          <b-link to="/settings/organization/integration"
            >integrations</b-link
          >
          before deleting your account.
        </p>
        <b-button
          type="submit"
          :disabled="loading || detectedInts"
          variant="danger"
          >Confirm</b-button
        >
      </b-form>
      <b-overlay :show="loading" no-wrap rounded></b-overlay>
    </b-modal>
  </div>
</template>
<script>
import RequestService from "@/services/RequestService";

export default {
  name: "Delete",
  data() {
    return {
      deleteModal: false,
      detectedInts: false,
      confirmDelete: "",
      loading: false,
    };
  },
  methods: {
    launchDelete() {
      if (
        this.$store.state.auth.user.integrations.length > 0 &&
        this.$store.state.auth.user.role !== "Teacher"
      )
        this.detectedInts = true;
      this.confirmDelete = "";
      this.deleteModal = true;
    },
    closeDelete() {
      this.confirmDelete = "";
      this.deleteModal = false;
    },
    async submitDelete() {
      if (this.confirmDelete.toLowerCase() === "delete") {
        this.loading = true;
        try {
          await RequestService.patchAccountProfileDelete();
        } catch (err) {
          if (err.message) this.makeToast(err.message, "Oh no", "danger");
        }
        this.loading = false;
        this.closeDelete();
        await this.$store.dispatch("auth/logout");
        this.$router.push("/?delete=true");
      } else {
        this.makeToast("Type 'delete' to confirm", "Oh no", "danger");
      }
    },
    makeToast(message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
