<template>
  <div>
    <b-navbar variant="white" class="py-3 fixed-top" toggleable="lg">
      <b-navbar-brand href="#start">
        <span>
          <img
            src="../../assets/logo_darkblue.png"
            width="32px"
            height="32px"
          />
        </span>
        <span class="align-middle" style="color: #1f4568">Safer</span>
        <span class="align-middle" style="color: #8198ae">Cloud</span>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item right class="px-1" href="#information"
            >Information</b-nav-item
          >
          <b-nav-item right class="px-1" href="#learn"
            >Learning Modules</b-nav-item
          >
          <b-nav-item right class="px-1" href="#contact">Contact Us</b-nav-item>
          <b-button
            right
            class="px-3"
            pill
            variant="outline-primary"
            to="/login"
            >Sign In</b-button
          >
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "LandingHeader",
};
</script>
<style>
.navbar-brand {
  padding-left: 1.5rem !important;
}
</style>
