<template>
  <div>
    <DashboardHeader organization="true" />
    <b-container fluid class="pt-5" style="max-width: 60rem">
      <b-row>
        <b-col md="auto">
          <OrganizationSidebar teacher="true" />
        </b-col>
        <b-col class="px-5">
          <Teacher />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import DashboardHeader from "@/components/headers/Dashboard.vue";
import OrganizationSidebar from "@/components/sidebars/Organization.vue";
import Teacher from "@/components/settings/organization/teacher/Teacher.vue";

export default {
  name: "OrganizationTeacher",
  components: {
    DashboardHeader,
    OrganizationSidebar,
    Teacher,
  },
};
</script>
