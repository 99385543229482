<template>
  <div>
    <b-button variant="success" to="/integration/create"
      ><b-icon-plus></b-icon-plus
    ></b-button>
    <b-skeleton-table
      v-if="loading"
      :rows="5"
      :columns="2"
      :table-props="{ striped: true }"
    />
    <b-table
      v-if="!loading"
      striped
      hover
      :items="integrations"
      :fields="fields"
    >
      <template #cell(Actions)="row">
        <b-button
          size="sm"
          variant="primary"
          @click="launchEdit(row.item, $event.target)"
          style="margin-right: 0.5rem"
        >
          <b-icon-pencil-square></b-icon-pencil-square>
        </b-button>
        <b-button
          size="sm"
          variant="primary"
          @click="launchDelete(row.item, $event.target)"
        >
          <b-icon-trash></b-icon-trash>
        </b-button>
      </template>
    </b-table>
    <b-modal
      :visible="editModal"
      title="Edit Integration"
      hide-footer
      @hide="closeEdit"
      body-class="position-static"
    >
      <b-form @submit.prevent="submitEdit">
        <label>Integration Name</label>
        <b-form-input required v-model="name" />
        <label>Authentication Endpoint URL</label>
        <b-form-input type="url" required v-model="authenticationEndpoint" />
        <label>Access Token Endpoint URL</label>
        <b-form-input type="url" required v-model="accesstokenEndpoint" />
        <label>Authentication Method</label>
        <b-form-radio-group required stacked v-model="method">
          <b-form-radio value="JWK_SET">JWK Set</b-form-radio>
          <b-form-radio value="JWK_KEY">JWK</b-form-radio>
          <b-form-radio value="RSA_KEY">RSA Key</b-form-radio>
        </b-form-radio-group>
        <b-form-group v-if="method === 'JWK_KEY'">
          <b-form-input required v-model="kty" placeholder="KTY"></b-form-input>
          <b-form-input required v-model="crv" placeholder="CRV"></b-form-input>
          <b-form-input required v-model="x" placeholder="X"></b-form-input>
          <b-form-input required v-model="y" placeholder="Y"></b-form-input>
          <b-form-input required v-model="kid" placeholder="KID"></b-form-input>
        </b-form-group>
        <b-textarea
          v-if="method === 'RSA_KEY'"
          v-model="rsa"
          placeholder="RSA Key"
          required
        />
        <b-input
          v-if="method === 'JWK_SET'"
          v-model="keySetURL"
          type="url"
          placeholder="JWKS URL"
          required
        />
        <b-button type="submit" variant="primary">Update</b-button>
      </b-form>
      <b-overlay :show="loading" no-wrap rounded></b-overlay>
    </b-modal>

    <b-modal
      :visible="deleteModal"
      title="Delete Integration"
      hide-footer
      @hide="closeDelete"
      body-class="position-static"
    >
      <p>Are you sure you want to delete {{ this.name }}?</p>
      <b-button @click="submitDelete" variant="danger">Delete</b-button>
      <b-overlay :show="loading" no-wrap rounded></b-overlay>
    </b-modal>
  </div>
</template>
<script>
import RequestService from "@/services/RequestService";

export default {
  name: "Integration",
  data() {
    return {
      fields: [
        { key: "name", label: "Integration Name", class: "text-center" },
        { key: "url", label: "Platform Url", class: "text-center" },
        { key: "Actions", class: "text-center" },
      ],
      integrations: [],
      name: "",
      integrationID: "",
      authenticationEndpoint: "",
      accesstokenEndpoint: "",
      method: "",
      clientId: "",
      url: "",
      keySetURL: "",
      kty: "",
      crv: "",
      x: "",
      y: "",
      kid: "",
      rsa: "",
      editModal: false,
      deleteModal: false,
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    if (this.$route.query.update) {
      try {
        const postJWTUpdate = await RequestService.postJWTUpdate();
        await this.$store.dispatch("auth/update", postJWTUpdate);
      } catch (err) {
        if (err.message) this.makeToast(err.message, "Oh no", "danger");
      }
    }
    await this.getIntegrations();
    this.loading = false;
  },
  methods: {
    launchEdit(integration) {
      this.name = integration.name;
      this.integrationID = integration.id;
      this.authenticationEndpoint = integration.authenticationEndpoint;
      this.accesstokenEndpoint = integration.accesstokenEndpoint;
      this.method = integration.authConfig.method;
      if (this.method === "JWK_SET")
        this.keySetURL = integration.authConfig.key;
      if (this.method === "RSA_KEY") this.rsa = integration.authConfig.key;
      if (this.method === "JWK_KEY") {
        const key = JSON.parse(integration.authConfig.key);
        this.kty = key.kty;
        this.crv = key.crv;
        this.x = key.x;
        this.y = key.y;
        this.kid = key.kid;
      }
      this.clientId = integration.clientId;
      this.url = integration.url;
      this.editModal = true;
    },
    launchDelete(integration) {
      this.clientId = integration.clientId;
      this.name = integration.name;
      this.integrationID = integration.id;
      this.url = integration.url;
      this.deleteModal = true;
    },
    closeEdit() {
      this.name = "";
      this.integrationID = "";
      this.authEndpoint = "";
      this.accesstokenEndpoint = "";
      this.method = "";
      this.clientId = "";
      this.url = "";
      this.keySetURL = "";
      this.kty = "";
      this.crv = "";
      this.x = "";
      this.y = "";
      this.kid = "";
      this.rsa = "";
      this.editModal = false;
    },
    closeDelete() {
      this.clientId = "";
      this.name = "";
      this.integrationID = "";
      this.url = "";
      this.deleteModal = false;
    },
    async getIntegrations(timestamp = false) {
      try {
        const getIntegration = await RequestService.getIntegration(timestamp);
        this.integrations = getIntegration.integrations;
      } catch (err) {
        if (err.message) this.makeToast(err.message, "Oh no", "danger");
      }
      return Promise.resolve(true);
    },
    async submitEdit() {
      this.loading = true;
      try {
        await RequestService.patchIntegration(
          this.url,
          this.clientId,
          this.name,
          this.authenticationEndpoint,
          this.accesstokenEndpoint,
          this.integrationID,
          this.method,
          this.keySetURL,
          this.kty,
          this.crv,
          this.x,
          this.y,
          this.kid,
          this.rsa
        );
        try {
          const postJWTUpdate = await RequestService.postJWTUpdate();
          await this.$store.dispatch("auth/update", postJWTUpdate);
        } catch (err) {
          if (err.message) this.makeToast(err.message, "Oh no", "danger");
        }
        await this.getIntegrations(true);
        this.makeToast(
          this.name + " was updated successfully",
          "Integration Updated",
          "success"
        );
      } catch (err) {
        if (err.message) this.makeToast(err.message, "Oh no", "danger");
      }
      this.loading = false;
      this.closeEdit();
    },
    async submitDelete() {
      this.loading = true;
      try {
        await RequestService.deleteIntegration(
          this.url,
          this.clientId,
          this.integrationID
        );
        try {
          const postJWTUpdate = await RequestService.postJWTUpdate();
          await this.$store.dispatch("auth/update", postJWTUpdate);
        } catch (err) {
          if (err.message) this.makeToast(err.message, "Oh no", "danger");
        }
        await this.getIntegrations(true);
        this.makeToast(
          this.name + " was deleted successfully",
          "Integration Deleted",
          "success"
        );
      } catch (err) {
        if (err.message) this.makeToast(err.message, "Oh no", "danger");
      }
      this.loading = false;
      this.closeDelete();
    },
    makeToast(message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
