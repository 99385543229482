<template>
  <div>
    <h3>Billing</h3>
    <hr />
    <b-button @click="launchBilling" :disabled="loading" variant="success">
      <b-spinner small v-if="loading"></b-spinner>
      Billing settings
    </b-button>
  </div>
</template>
<script>
import RequestService from "@/services/RequestService";

export default {
  name: "Billing",
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async launchBilling() {
      this.loading = true;
      try {
        const url = await RequestService.postBilling();
        window.location = url;
      } catch (err) {
        if (err.message) this.makeToast(err.message, "Oh no", "danger");
      }
      this.loading = false;
    },
    makeToast(message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
