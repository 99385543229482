<template>
  <div>
    <h3>Change password</h3>
    <hr />
    <b-form @submit.prevent="updatePassword">
      <label>Old password</label>
      <b-skeleton v-if="loading" type="input" width="20rem"></b-skeleton>
      <b-input
        class="mt-1"
        v-model="oldPassword"
        v-if="!loading"
        style="max-width: 20rem"
        type="password"
        required
      />
      <label class="mt-2">New password</label>
      <b-skeleton v-if="loading" type="input" width="20rem"></b-skeleton>
      <b-input
        class="mt-1"
        v-model="password"
        v-if="!loading"
        :state="minLen"
        style="max-width: 20rem"
        type="password"
        required
      />
      <b-form-invalid-feedback :state="minLen">
        Password must be at least 8 characters
      </b-form-invalid-feedback>
      <label class="mt-2">Confirm new password</label>
      <b-skeleton v-if="loading" type="input" width="20rem"></b-skeleton>
      <b-input
        class="mt-1"
        v-model="confirmPassword"
        v-if="!loading"
        :state="match"
        style="max-width: 20rem"
        type="password"
        required
      />
      <b-form-invalid-feedback :state="match">
        Password must match
      </b-form-invalid-feedback>
      <div class="mt-3">
        <b-button
          type="submit"
          variant="success"
          style="max-width: 10rem; margin-right: 1rem"
          :disabled="loading"
          >Update password</b-button
        >
        <b-link :disabled="loading" to="/password/reset"
          >I forgot my password</b-link
        >
      </div>
    </b-form>
  </div>
</template>
<script>
import RequestService from "@/services/RequestService";

export default {
  name: "Password",
  data() {
    return {
      oldPassword: "",
      password: "",
      confirmPassword: "",
      loading: false,
    };
  },
  computed: {
    minLen() {
      if (this.password.length === 0) return null;
      else return this.password.length >= 8;
    },
    match() {
      if (this.confirmPassword.length === 0) return null;
      else return this.confirmPassword === this.password;
    },
  },
  methods: {
    async updatePassword() {
      this.loading = true;
      try {
        await RequestService.patchAccountSecurityPassword(
          this.oldPassword,
          this.password,
          this.confirmPassword
        );
        this.makeToast(
          "Your password was updated successfully",
          "Password Updated",
          "success"
        );
      } catch (err) {
        if (err.message) this.makeToast(err.message, "Oh no", "danger");
      }
      this.oldPassword = "";
      this.password = "";
      this.confirmPassword = "";
      this.loading = false;
    },
    makeToast(message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
