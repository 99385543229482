<template>
  <div>
    <b-container v-if="loading">
      <b-overlay opacity="0" :show="loading" no-wrap></b-overlay>
    </b-container>
    <b-container v-if="!loading">
      <div v-if="step === 1">
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent interdum vestibulum elit. In dictum feugiat eros, et pharetra lorem mattis vel. Etiam at orci eu diam rhoncus convallis. Pellentesque eu vehicula turpis."
          label="Tool Name:"
          label-for="toolName"
        >
          <b-input-group>
            <b-form-input
              id="toolName"
              style="max-width: 17rem"
              onClick="this.setSelectionRange(0, this.value.length); navigator.clipboard.writeText(this.value);"
              value="SaferCloud"
              readonly
            />
            <b-input-group-append
              ><b-button @click="copy('toolName')" variant="outline-secondary"
                ><b-icon-files /></b-button
            ></b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent interdum vestibulum elit. In dictum feugiat eros, et pharetra lorem mattis vel. Etiam at orci eu diam rhoncus convallis. Pellentesque eu vehicula turpis."
          label="Tool URL:"
          label-for="toolUrl"
        >
          <b-input-group>
            <b-form-input
              id="toolUrl"
              style="max-width: 17rem"
              onClick="this.setSelectionRange(0, this.value.length); navigator.clipboard.writeText(this.value);"
              value="https://lti.safercloud.io/"
              readonly
            />
            <b-input-group-append
              ><b-button @click="copy('toolUrl')" variant="outline-secondary"
                ><b-icon-files /></b-button
            ></b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent interdum vestibulum elit. In dictum feugiat eros, et pharetra lorem mattis vel. Etiam at orci eu diam rhoncus convallis. Pellentesque eu vehicula turpis."
          label="LTI Version:"
          label-for="ltiVer"
        >
          <b-form-input
            id="ltiVer"
            style="max-width: 17rem"
            value="LTI 1.3"
            readonly
          />
        </b-form-group>

        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent interdum vestibulum elit. In dictum feugiat eros, et pharetra lorem mattis vel. Etiam at orci eu diam rhoncus convallis. Pellentesque eu vehicula turpis."
          label="Public Key Type:"
          label-for="pubKey"
        >
          <b-form-input
            id="pubKey"
            style="max-width: 17rem"
            value="Keyset URL"
            readonly
          />
        </b-form-group>

        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent interdum vestibulum elit. In dictum feugiat eros, et pharetra lorem mattis vel. Etiam at orci eu diam rhoncus convallis. Pellentesque eu vehicula turpis."
          label="Initiate Login URL:"
          label-for="loginUrl"
        >
          <b-input-group>
            <b-form-input
              id="loginUrl"
              style="max-width: 17rem"
              onClick="this.setSelectionRange(0, this.value.length); navigator.clipboard.writeText(this.value);"
              value="https://lti.safercloud.io/login"
              readonly
            />
            <b-input-group-append
              ><b-button @click="copy('loginUrl')" variant="outline-secondary"
                ><b-icon-files /></b-button
            ></b-input-group-append>
          </b-input-group>
        </b-form-group>

        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent interdum vestibulum elit. In dictum feugiat eros, et pharetra lorem mattis vel. Etiam at orci eu diam rhoncus convallis. Pellentesque eu vehicula turpis."
          label="Redirection URI(s):"
          label-for="redirUri"
        >
          <b-input-group>
            <b-form-textarea
              id="redirUri"
              style="max-width: 17rem"
              onClick="this.setSelectionRange(0, this.value.length); navigator.clipboard.writeText(this.value);"
              value="https://lti.safercloud.io/
https://lti.safercloud.io/launch
https://lti.safercloud.io/deeplink"
              no-resize
              rows="3"
              readonly
            />
            <b-input-group-append
              ><b-button @click="copy('redirUri')" variant="outline-secondary"
                ><b-icon-files /></b-button
            ></b-input-group-append>
          </b-input-group>
        </b-form-group>

        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent interdum vestibulum elit. In dictum feugiat eros, et pharetra lorem mattis vel. Etiam at orci eu diam rhoncus convallis. Pellentesque eu vehicula turpis."
          label="Custom Parameters:"
          label-for="custom"
        >
          <b-input-group>
            <b-form-input
              id="custom"
              style="max-width: 17rem"
              onClick="this.setSelectionRange(0, this.value.length); navigator.clipboard.writeText(this.value);"
              :value="'license=' + license"
              readonly
            />
            <b-input-group-append
              ><b-button @click="copy('custom')" variant="outline-secondary"
                ><b-icon-files /></b-button
            ></b-input-group-append>
          </b-input-group>
        </b-form-group>

        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent interdum vestibulum elit. In dictum feugiat eros, et pharetra lorem mattis vel. Etiam at orci eu diam rhoncus convallis. Pellentesque eu vehicula turpis."
          label="Supports Deep Linking:"
          label-for="deepLink"
        >
          <b-form-input
            id="deepLink"
            style="max-width: 17rem"
            value="True"
            readonly
          />
        </b-form-group>
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent interdum vestibulum elit. In dictum feugiat eros, et pharetra lorem mattis vel. Etiam at orci eu diam rhoncus convallis. Pellentesque eu vehicula turpis."
          label="Content Selection URL:"
          label-for="contentUrl"
        >
          <b-input-group>
            <b-form-input
              id="contentUrl"
              style="max-width: 17rem"
              onClick="this.setSelectionRange(0, this.value.length); navigator.clipboard.writeText(this.value);"
              value="https://lti.safercloud.io/deeplink"
              readonly
            />
            <b-input-group-append
              ><b-button @click="copy('contentUrl')" variant="outline-secondary"
                ><b-icon-files /></b-button
            ></b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-button @click="goTo(2)" variant="primary">Next</b-button>
      </div>
      <div v-if="step === 2">
        <b-form @submit.prevent="createIntegration">
          <label>Integration Name</label>
          <b-input v-model="name" required style="max-width: 17rem" />
          <label>LMS Patform URL</label>
          <b-input type="url" v-model="url" required style="max-width: 17rem" />
          <label>Client ID</label>
          <b-input v-model="clientId" required style="max-width: 17rem" />
          <label>Authentication Endpoint URL</label>
          <b-input
            v-model="authenticationEndpoint"
            required
            type="url"
            style="max-width: 17rem"
          />
          <label>Access Token Endpoint URL</label>
          <b-input
            v-model="accesstokenEndpoint"
            required
            type="url"
            style="max-width: 17rem"
          />
          <label>Authentication Method</label>
          <b-form-radio-group required stacked v-model="method">
            <b-form-radio value="JWK_SET">JWK Set</b-form-radio>
            <b-form-radio value="JWK_KEY">JWK</b-form-radio>
            <b-form-radio value="RSA_KEY">RSA Key</b-form-radio>
          </b-form-radio-group>
          <b-form-group v-if="method === 'JWK_KEY'">
            <b-form-input
              required
              v-model="kty"
              placeholder="KTY"
            ></b-form-input>
            <b-form-input
              required
              v-model="crv"
              placeholder="CRV"
            ></b-form-input>
            <b-form-input required v-model="x" placeholder="X"></b-form-input>
            <b-form-input required v-model="y" placeholder="Y"></b-form-input>
            <b-form-input
              required
              v-model="kid"
              placeholder="KID"
            ></b-form-input>
          </b-form-group>
          <b-textarea
            v-if="method === 'RSA_KEY'"
            v-model="rsa"
            placeholder="RSA Key"
            required
          />
          <b-input
            v-if="method === 'JWK_SET'"
            v-model="keySetURL"
            placeholder="JWKS URL"
            type="url"
            required
          />
          <b-button @click="goTo(1)" variant="primary">Previous</b-button>
          <b-button type="submit" variant="primary">Next</b-button>
        </b-form>
      </div>
      <div class="text-center" v-if="step === 3">
        <b-overlay opacity="0" :show="step === 3" no-wrap></b-overlay>
      </div>
    </b-container>
  </div>
</template>
<script>
import RequestService from "@/services/RequestService";

export default {
  name: "Blackboard",
  data() {
    return {
      step: 1,
      url: "",
      clientId: "",
      name: "",
      authenticationEndpoint: "",
      accesstokenEndpoint: "",
      license: "",
      method: "",
      kty: "",
      crv: "",
      x: "",
      y: "",
      kid: "",
      rsa: "",
      keySetURL: "",
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    try {
      const getIntegrationJWT = await RequestService.getIntegrationJWT();
      this.license = getIntegrationJWT.token;
    } catch (err) {
      if (err.message) this.makeToast(err.message, "Oh no", "danger");
    }
    this.loading = false;
  },
  methods: {
    goTo(step) {
      this.step = step;
      this.$emit("step", step);
    },
    copy(inputId) {
      navigator.clipboard.writeText(document.getElementById(inputId).value);
    },
    async createIntegration() {
      this.step = 3;
      try {
        await RequestService.postIntegration(
          this.url,
          this.clientId,
          this.name,
          this.authenticationEndpoint,
          this.accesstokenEndpoint,
          this.license,
          this.method,
          this.keySetURL,
          this.kty,
          this.crv,
          this.x,
          this.y,
          this.kid,
          this.rsa
        );
      } catch (err) {
        if (err.message) this.makeToast(err.message, "Oh no", "danger");
      }
      this.$router.push("/settings/organization/integration?update=true");
    },
    makeToast(message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
