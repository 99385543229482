var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-navbar',{staticClass:"py-3",attrs:{"variant":"light","toggleable":"lg"}},[_c('b-navbar-brand',{attrs:{"to":"/dashboard"}},[_c('span',[_c('img',{attrs:{"src":require("../../assets/logo_darkblue.png"),"width":"32px","height":"32px"}})])]),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',[(
            _vm.$store.state.auth.user.role === 'EduAdmin' ||
            _vm.$store.state.auth.user.role === 'Teacher'
          )?_c('b-nav-item',{staticClass:"px-1",attrs:{"to":"/dashboard","active":_vm.dashboard ? true : false}},[_vm._v("Dashboard")]):_vm._e(),(_vm.$store.state.auth.user.role === 'SysAdmin')?_c('b-nav-item',{staticClass:"px-1",attrs:{"to":"/approval","active":_vm.approval ? true : false}},[_vm._v("Approvals")]):_vm._e(),(
            _vm.$store.state.auth.user.role === 'EduAdmin' ||
            _vm.$store.state.auth.user.role === 'Teacher'
          )?_c('b-nav-item',{staticClass:"px-1",attrs:{"to":"/guides","active":_vm.guides ? true : false}},[_vm._v("Guides")]):_vm._e(),(
            _vm.$store.state.auth.user.role === 'EduAdmin' ||
            _vm.$store.state.auth.user.role === 'Teacher'
          )?_c('b-nav-item',{staticClass:"px-1",attrs:{"to":"/dashboard","active":_vm.demo ? true : false}},[_vm._v("Demo")]):_vm._e(),(_vm.$store.state.auth.user.role === 'EduAdmin')?_c('b-nav-item',{staticClass:"px-1",attrs:{"to":"/settings/organization/profile","active":_vm.organization ? true : false}},[_vm._v("Organization Settings")]):_vm._e(),(
            _vm.$store.state.auth.user.role === 'EduAdmin' ||
            _vm.$store.state.auth.user.role === 'Teacher'
          )?_c('b-nav-item',{staticClass:"px-1",attrs:{"to":"/settings/account/profile","active":_vm.account ? true : false}},[_vm._v("Account Settings")]):_vm._e(),_c('b-button',{staticClass:"px-3",attrs:{"pill":"","variant":"outline-primary"},on:{"click":_vm.logout}},[_vm._v("Log Out")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }