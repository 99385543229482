<template>
  <div id="footer">
    <footer class="text-muted">
      <div
        class="
          d-flex
          flex-column flex-md-row
          align-items-center
          p-3
          px-md-4
          mb-1
          border-top
        "
      >
        <h6 class="my-0 mr-md-auto">&copy; SaferCloud 2021</h6>
        <div style="text-align: center; color: lightgrey; font-size: 14px">
          Icons by
          <a
            href="https://www.flaticon.com/authors/freepik"
            title="Freepik"
            style="color: lightgrey"
            >Freepik</a
          >
          from
          <a
            href="https://www.flaticon.com/"
            title="Flaticon"
            style="color: lightgrey"
            >www.flaticon.com</a
          >
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "LandingFooter",
};
</script>
