import jwt_decode from "jwt-decode";

const storage = JSON.parse(localStorage.getItem("user"));
let user = null;
if (storage) user = jwt_decode(storage.token);
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    async login({ commit }, postLogin) {
      try {
        const token = jwt_decode(postLogin.token);
        commit("loginSuccess", token);
        return Promise.resolve(postLogin);
      } catch (err) {
        commit("loginFailure");
        return Promise.reject(false);
      }
    },
    async update({ commit }, postJWTUpdate) {
      try {
        const token = jwt_decode(postJWTUpdate.token);
        commit("update", token);
        return true;
      } catch (err) {
        return false;
      }
    },
    logout({ commit }) {
      localStorage.removeItem("user");
      commit("logout");
    },
  },
  mutations: {
    loginSuccess(state, token) {
      state.status.loggedIn = true;
      state.user = token;
    },
    update(state, token) {
      state.user = token;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
  },
};
