<template>
  <div>
    <DashboardHeader account="true" />
    <b-container fluid class="pt-5" style="max-width: 60rem">
      <b-row>
        <b-col md="auto">
          <AccountSidebar notification="true" />
        </b-col>
        <b-col class="px-5">
          <Email />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import DashboardHeader from "@/components/headers/Dashboard.vue";
import AccountSidebar from "@/components/sidebars/Account.vue";
import Email from "@/components/settings/account/notifications/Email.vue";

export default {
  name: "AccountNotifications",
  components: {
    DashboardHeader,
    AccountSidebar,
    Email,
  },
};
</script>
