<template>
  <div>
    <h3>Email notification preferences</h3>
    <hr />
    <b-form @submit.prevent="updateNotification">
      <b-skeleton v-if="loading"></b-skeleton>
      <b-form-checkbox v-if="!loading" v-model="marketingEmails"
        >I want to receive promotional and marketing emails from
        SaferCloud</b-form-checkbox
      >
      <b-button :disabled="loading" type="submit" variant="success" class="mt-3"
        >Update</b-button
      >
    </b-form>
  </div>
</template>
<script>
import RequestService from "@/services/RequestService";

export default {
  name: "Email",
  data() {
    return {
      marketingEmails: false,
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    await this.getNotification();
    this.loading = false;
  },
  methods: {
    async getNotification() {
      try {
        const getAccountNotificationsEmail =
          await RequestService.getAccountNotificationsEmail();
        this.marketingEmails = getAccountNotificationsEmail.marketingEmails;
      } catch (err) {
        if (err.message) this.makeToast(err.message, "Oh no", "danger");
      }
      return Promise.resolve(true);
    },
    async updateNotification() {
      this.loading = true;
      try {
        await RequestService.patchAccountNotificationsEmail(
          this.marketingEmails
        );
        this.makeToast(
          "Email notifications updated successfully",
          "Notifications Updated",
          "success"
        );
      } catch (err) {
        if (err.message) this.makeToast(err.message, "Oh no", "danger");
      }
      this.loading = false;
    },
    makeToast(message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
