<template>
  <div>
    <b-card no-body header="Account Settings">
      <b-list-group flush>
        <b-link to="/settings/account/profile"
          ><b-list-group-item :active="profile ? true : false"
            >Profile Settings</b-list-group-item
          ></b-link
        >
        <b-link to="/settings/account/security"
          ><b-list-group-item :active="security ? true : false"
            >Account Security</b-list-group-item
          ></b-link
        >
        <b-link to="/settings/account/notifications"
          ><b-list-group-item :active="notification ? true : false"
            >Notifications</b-list-group-item
          ></b-link
        >
      </b-list-group>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "AccountSidebar",
  props: ["profile", "security", "notification"],
};
</script>
