<template>
  <div>
    <DashboardHeader organization="true" />
    <b-container fluid class="pt-5" style="max-width: 60rem">
      <b-row>
        <b-col md="auto">
          <OrganizationSidebar integration="true" />
        </b-col>
        <b-col class="px-5">
          <Integration />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import DashboardHeader from "@/components/headers/Dashboard.vue";
import OrganizationSidebar from "@/components/sidebars/Organization.vue";
import Integration from "@/components/settings/organization/integration/Integration.vue";

export default {
  name: "OrganizationIntegration",
  components: {
    DashboardHeader,
    OrganizationSidebar,
    Integration,
  },
};
</script>
