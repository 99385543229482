import Vue from "vue";
import store from "@/store/index.js";
import VueRouter from "vue-router";
import Landing from "../views/Landing.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import Terms from "../views/Terms.vue";
import Privacy from "../views/Privacy.vue";
import Security from "../views/Security.vue";
import Contact from "../views/Contact.vue";
import PasswordReset from "../views/PasswordReset.vue";
import PasswordSet from "../views/PasswordSet.vue";
import EmailSet from "../views/EmailSet.vue";
import Approval from "../views/Approval.vue";
import Dashboard from "../views/Dashboard.vue";
import Guides from "../views/Guides.vue";
import AccountNotifications from "../views/AccountNotifications.vue";
import AccountSecurity from "../views/AccountSecurity.vue";
import AccountProfile from "../views/AccountProfile.vue";
import OrganizationIntegration from "../views/OrganizationIntegration.vue";
import CreateIntegration from "../views/CreateIntegration.vue";
import OrganizationBilling from "../views/OrganizationBilling.vue";
import OrganizationProfile from "../views/OrganizationProfile.vue";
import OrganizationTeacher from "../views/OrganizationTeacher.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Landing",
    component: Landing,
    meta: { roles: ["any"], perms: ["any"], logged: "any" },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { roles: ["any"], perms: ["any"], logged: "out" },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: { roles: ["any"], perms: ["any"], logged: "out" },
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms,
    meta: { roles: ["any"], perms: ["any"], logged: "any" },
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
    meta: { roles: ["any"], perms: ["any"], logged: "any" },
  },
  {
    path: "/security",
    name: "Security",
    component: Security,
    meta: { roles: ["any"], perms: ["any"], logged: "any" },
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    meta: { roles: ["any"], perms: ["any"], logged: "any" },
  },
  {
    path: "/password/reset",
    name: "PasswordReset",
    component: PasswordReset,
    meta: { roles: ["any"], perms: ["any"], logged: "any" },
  },
  {
    path: "/password/set",
    name: "PasswordSet",
    component: PasswordSet,
    meta: { roles: ["any"], perms: ["any"], logged: "any" },
  },
  {
    path: "/email/set",
    name: "EmailSet",
    component: EmailSet,
    meta: { roles: ["any"], perms: ["any"], logged: "any" },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { roles: ["any"], perms: ["any"], logged: "in" },
  },
  {
    path: "/approval",
    name: "Approval",
    component: Approval,
    meta: { roles: ["SysAdmin"], perms: ["any"], logged: "in" },
  },
  {
    path: "/guides",
    name: "Guides",
    component: Guides,
    meta: { roles: ["any"], perms: ["any"], logged: "in" },
  },
  {
    path: "/settings/account/profile",
    name: "AccountProfile",
    component: AccountProfile,
    meta: { roles: ["any"], perms: ["any"], logged: "in" },
  },
  {
    path: "/settings/account/security",
    name: "AccountSecurity",
    component: AccountSecurity,
    meta: { roles: ["any"], perms: ["any"], logged: "in" },
  },
  {
    path: "/settings/account/notifications",
    name: "AccountNotifications",
    component: AccountNotifications,
    meta: { roles: ["any"], perms: ["any"], logged: "in" },
  },
  {
    path: "/settings/organization/profile",
    name: "OrganizationProfile",
    component: OrganizationProfile,
    meta: { roles: ["EduAdmin"], perms: ["any"], logged: "in" },
  },
  {
    path: "/settings/organization/billing",
    name: "OrganizationBilling",
    component: OrganizationBilling,
    meta: { roles: ["EduAdmin"], perms: ["any"], logged: "in" },
  },
  {
    path: "/settings/organization/integration",
    name: "OrganizationIntegration",
    component: OrganizationIntegration,
    meta: { roles: ["EduAdmin"], perms: ["Paid"], logged: "in" },
  },
  {
    path: "/integration/create",
    name: "CreateIntegration",
    component: CreateIntegration,
    meta: { roles: ["EduAdmin"], perms: ["Paid"], logged: "in" },
  },
  {
    path: "/settings/organization/teacher",
    name: "OrganizationTeacher",
    component: OrganizationTeacher,
    meta: { roles: ["EduAdmin"], perms: ["Paid"], logged: "in" },
  },
  {
    path: "*",
    redirect: "/",
    meta: { roles: ["any"], perms: ["any"], logged: "any" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const roles = to.meta.roles;
  const perms = to.meta.perms;
  const logged = to.meta.logged;

  if (logged === "in") {
    if (!store.state.auth.status.loggedIn)
      return next({ path: "login", query: { returnTo: to.fullPath } });

    if (Date.now() >= store.state.auth.user.exp * 1000) {
      await store.dispatch("auth/logout");
      return next({ path: "login", query: { returnTo: to.fullPath } });
    }

    let roleOkay = false;
    if (roles.includes("any")) roleOkay = true;
    else {
      for (let role of roles) {
        if (role === store.state.auth.user.role) roleOkay = true;
      }
    }

    let permOkay = false;
    if (perms.includes("any")) permOkay = true;
    else if (perms.every((i) => store.state.auth.user.permissions.includes(i)))
      permOkay = true;

    if (roleOkay && permOkay) return next();
    else return next({ path: "/dashboard" });
  } else if (logged === "out" && store.state.auth.status.loggedIn) {
    if (to.query.passwordUpdate)
      return next({ path: "/dashboard?passwordUpdate=true" });
    else return next({ path: "/dashboard" });
  } else return next();
});

export default router;
