<template>
  <div>
    <b-navbar variant="light" class="py-3" toggleable="lg">
      <b-navbar-brand to="/dashboard">
        <span>
          <img
            src="../../assets/logo_darkblue.png"
            width="32px"
            height="32px"
          />
        </span>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item
            class="px-1"
            to="/dashboard"
            :active="dashboard ? true : false"
            v-if="
              $store.state.auth.user.role === 'EduAdmin' ||
              $store.state.auth.user.role === 'Teacher'
            "
            >Dashboard</b-nav-item
          >
          <b-nav-item
            class="px-1"
            to="/approval"
            v-if="$store.state.auth.user.role === 'SysAdmin'"
            :active="approval ? true : false"
            >Approvals</b-nav-item
          >
          <b-nav-item
            class="px-1"
            to="/guides"
            :active="guides ? true : false"
            v-if="
              $store.state.auth.user.role === 'EduAdmin' ||
              $store.state.auth.user.role === 'Teacher'
            "
            >Guides</b-nav-item
          >
          <b-nav-item
            class="px-1"
            to="/dashboard"
            :active="demo ? true : false"
            v-if="
              $store.state.auth.user.role === 'EduAdmin' ||
              $store.state.auth.user.role === 'Teacher'
            "
            >Demo</b-nav-item
          >
          <b-nav-item
            class="px-1"
            v-if="$store.state.auth.user.role === 'EduAdmin'"
            to="/settings/organization/profile"
            :active="organization ? true : false"
            >Organization Settings</b-nav-item
          >
          <b-nav-item
            class="px-1"
            to="/settings/account/profile"
            v-if="
              $store.state.auth.user.role === 'EduAdmin' ||
              $store.state.auth.user.role === 'Teacher'
            "
            :active="account ? true : false"
            >Account Settings</b-nav-item
          >
          <b-button class="px-3" @click="logout" pill variant="outline-primary"
            >Log Out</b-button
          >
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "DashboardHeader",
  props: ["dashboard", "approval", "guides", "demo", "organization", "account"],
  methods: {
    async logout() {
      await this.$store.dispatch("auth/logout");
      this.$router.push("/?logout=true");
    },
  },
};
</script>
<style>
.navbar-brand {
  padding-left: 1.5rem !important;
}
</style>
