<template>
  <div>
    <h3 class="mt-5">Email</h3>
    <hr />
    <b-form @submit.prevent="updateEmail">
      <label class="mt-2">Email</label>
      <b-skeleton v-if="loading" type="input" width="20rem"></b-skeleton>
      <b-form-input
        v-if="!loading"
        v-model="email"
        type="email"
        class="mt-1"
        style="max-width: 20rem"
        :disabled="resetEmail !== ''"
        required
      />
      <div
        style="width: 100%; max-width: 20rem"
        class="d-flex justify-content-between"
      >
        <div>
          <b-link :disabled="loading" v-if="resetEmail !== ''" @click="resend"
            >Resend Email</b-link
          >
        </div>
        <div>
          <b-link
            :disabled="loading"
            class="text-left"
            v-if="resetEmail !== ''"
            @click="cancel"
            >Cancel</b-link
          >
        </div>
      </div>

      <b-button
        type="submit"
        variant="success"
        class="mt-3"
        v-if="resetEmail === ''"
        :disabled="loading"
        >Update email</b-button
      >
    </b-form>
  </div>
</template>
<script>
import RequestService from "@/services/RequestService";

export default {
  name: "Email",
  data() {
    return {
      email: "",
      resetEmail: "",
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    await this.getEmail();
    this.loading = false;
  },
  methods: {
    async getEmail(timestamp = false) {
      try {
        const getAccountProfileEmail =
          await RequestService.getAccountProfileEmail(timestamp);
        this.email = getAccountProfileEmail.email;
        if (getAccountProfileEmail.resetEmail) {
          this.resetEmail = getAccountProfileEmail.resetEmail;
          this.email = this.resetEmail;
        }
      } catch (err) {
        if (err.message) this.makeToast(err.message, "Oh no", "danger");
      }
      return Promise.resolve(true);
    },
    async updateEmail() {
      this.loading = true;
      try {
        await RequestService.patchAccountProfileEmail(this.email);
        await this.getEmail(true);
        this.makeToast(
          "Check " + this.resetEmail + "'s inbox for instructions",
          "Email changed requested",
          "success"
        );
      } catch (err) {
        if (err.message) this.makeToast(err.message, "Oh no", "danger");
      }
      this.loading = false;
    },
    async cancel() {
      this.loading = true;
      try {
        await RequestService.patchAccountProfileEmailCancel();
        await this.getEmail(true);
        this.resetEmail = "";
      } catch (err) {
        if (err.message) this.makeToast(err.message, "Oh no", "danger");
      }
      this.loading = false;
    },
    async resend() {
      this.loading = true;
      try {
        await RequestService.patchAccountProfileEmailResend();
        this.makeToast(
          "Check " + this.resetEmail + "'s inbox for instructions",
          "Email resent",
          "success"
        );
      } catch (err) {
        if (err.message) this.makeToast(err.message, "Oh no", "danger");
      }
      this.loading = false;
    },
    makeToast(message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
