<template>
  <div>
    <h3>Profile</h3>
    <hr />
    <label>Organization Name</label>
    <b-form @submit.prevent="updateProfile">
      <b-skeleton v-if="loading" type="input" animation="wave" width="20rem" />
      <b-input
        v-if="!loading"
        v-model="organization"
        class="mt-1"
        style="max-width: 20rem"
        required
      />
      <label class="mt-2">Organization Location</label>
      <b-skeleton v-if="loading" type="input" animation="wave" width="20rem" />
      <b-input
        v-if="!loading"
        v-model="organizationLocation"
        class="mt-1"
        style="max-width: 20rem"
        required
      />
      <label class="mt-2">Organization Type</label>
      <b-skeleton v-if="loading" type="input" animation="wave" width="20rem" />
      <b-form-radio-group
        required
        stacked
        v-model="organizationType"
        v-if="!loading"
        id="organizationType"
      >
        <b-form-radio v-model="organizationType" value="K-5"
          >Elementary organization (K-5)</b-form-radio
        >
        <b-form-radio v-model="organizationType" value="6-8"
          >Middle School (6-8)</b-form-radio
        >
        <b-form-radio v-model="organizationType" value="9-12"
          >High School</b-form-radio
        >
        <b-form-radio v-model="organizationType" value="Higher Ed"
          >Higher Education</b-form-radio
        >
        <b-form-radio v-model="organizationType" value="Disctrict"
          >School District</b-form-radio
        >
        <b-form-radio v-model="organizationType" value="Other"
          >Other</b-form-radio
        >
        <b-form-input
          v-if="organizationType === 'Other'"
          placeholder="Please explain"
          v-model="organizationTypeOther"
        ></b-form-input>
      </b-form-radio-group>
      <b-button :disabled="loading" type="submit" variant="success" class="mt-3"
        >Update profile</b-button
      >
    </b-form>
  </div>
</template>
<script>
import RequestService from "@/services/RequestService";

export default {
  name: "Profile",
  data() {
    return {
      organization: "",
      organizationLocation: "",
      organizationType: "",
      organizationTypeOther: "",
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    await this.getProfile();
    this.loading = false;
  },
  methods: {
    async getProfile() {
      try {
        const getOrganizationProfile =
          await RequestService.getOrganizationProfile();
        this.organization = getOrganizationProfile.organization;
        this.organizationLocation = getOrganizationProfile.organizationLocation;
        this.organizationType = getOrganizationProfile.organizationType;
        const organizationTypes = [
          "K-5",
          "6-8",
          "9-12",
          "Higher Ed",
          "District",
        ];
        if (!organizationTypes.includes(this.organizationType)) {
          this.organizationTypeOther = this.organizationType;
          this.organizationType = "Other";
        }
      } catch (err) {
        if (err.message) this.makeToast(err.message, "Oh no", "danger");
      }
      return Promise.resolve(true);
    },
    async updateProfile() {
      this.loading = true;
      let organizationType = this.organizationType;
      if (this.organizationType === "Other")
        organizationType = this.organizationTypeOther;
      else this.organizationTypeOther = "";
      try {
        await RequestService.patchOrganizationProfile(
          this.organization,
          this.organizationLocation,
          organizationType
        );
        this.makeToast(
          "Your profile was updated successfully",
          "Profile Updated",
          "success"
        );
      } catch (err) {
        if (err.message) this.makeToast(err.message, "Oh no", "danger");
      }
      this.loading = false;
    },
    makeToast(message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
