<template>
  <div>
    <DashboardHeader guides="true" />
  </div>
</template>

<script>
import DashboardHeader from "@/components/headers/Dashboard.vue";

export default {
  name: "Guides",
  components: {
    DashboardHeader,
  },
  data() {
    return {
      email: "",
    };
  },
};
</script>
