<template>
  <div>
    <b-button to="/settings/organization/integration">Cancel</b-button>
    <b-form-select v-if="step === 1" v-model="LMS">
      <b-form-select-option :value="null">Choose your LMS</b-form-select-option>
      <b-form-select-option value="Blackboard">Blackboard</b-form-select-option>
      <b-form-select-option value="Moodle">Moodle</b-form-select-option>
      <b-form-select-option value="Canvas">Canvas</b-form-select-option>
      <b-form-select-option value="Other">Other</b-form-select-option>
    </b-form-select>
    <Blackboard @step="updateStep" v-if="LMS === 'Blackboard'" />
    <Moodle @step="updateStep" v-if="LMS === 'Moodle'" />
    <Canvas @step="updateStep" v-if="LMS === 'Canvas'" />
    <Other @step="updateStep" v-if="LMS === 'Other'" />
  </div>
</template>
<script>
import Blackboard from "@/components/integration/lms/Blackboard.vue";
import Moodle from "@/components/integration/lms/Moodle.vue";
import Canvas from "@/components/integration/lms/Canvas.vue";
import Other from "@/components/integration/lms/Other.vue";

export default {
  name: "Create",
  components: {
    Blackboard,
    Moodle,
    Canvas,
    Other,
  },
  data() {
    return {
      LMS: null,
      step: 1,
    };
  },
  methods: {
    updateStep(step) {
      this.step = step;
    },
  },
};
</script>
