<template>
  <div>
    <DashboardHeader dashboard="true" />
  </div>
</template>

<script>
import DashboardHeader from "@/components/headers/Dashboard.vue";

export default {
  name: "Dashboard",
  components: {
    DashboardHeader,
  },
  created() {
    if (this.$route.query.emailUpdate)
      this.makeToast("Email successfully updated", "Email Updated", "success");
    if (this.$route.query.passwordUpdate)
      this.makeToast(
        "Your password was updated successfully",
        "Password Updated",
        "success"
      );
  },
  data() {
    return {
      email: "",
    };
  },
  methods: {
    makeToast(message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
