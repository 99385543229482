<template>
  <div>
    <h3>Checkout</h3>
    <hr />
    <stripe-checkout
      ref="checkoutRef"
      :pk="publishableKey"
      :session-id="sessionId"
    />
    <label>Students</label>
    <b-input-group class="justify-content-center" style="max-width: 10rem">
      <b-input-group-prepend>
        <b-button
          @click="decrease"
          style="outline: none"
          variant="outline-secondary"
          :disabled="loading"
          >-</b-button
        >
      </b-input-group-prepend>
      <b-input
        v-model="quantity"
        @update="priceCalc"
        style="text-align: center"
        oninput="validity.valid||(value='');"
        type="number"
        max="9999999"
        min="0"
        step="1"
        placeholder="0"
        :disabled="loading"
      />
      <b-input-group-append>
        <b-button
          @click="increase"
          style="outline: none"
          variant="outline-secondary"
          :disabled="loading"
          >+</b-button
        >
      </b-input-group-append>
    </b-input-group>
    <p class="mt-2">
      Estimated price (final price is calculated on next step): ${{ price }} /
      year
    </p>
    <b-button @click="launchCheckout()" :disabled="loading" variant="success">
      <b-spinner small v-if="loading"></b-spinner>
      Checkout
    </b-button>
  </div>
</template>
<script>
import RequestService from "@/services/RequestService";

export default {
  name: "Checkout",
  data() {
    return {
      quantity: "",
      price: 0,
      publishableKey:
        "pk_test_51IRPkSAUbcW9wYN5ybnzOmciQCCqpIveGHuFQqeMysOA3ZBBSDcBwe2Jlzrb4Zia49M4dUIRknx9tG8DXYF9V16J00U4ipUoEN",
      sessionId: "",
      loading: false,
    };
  },
  methods: {
    async launchCheckout() {
      this.loading = true;
      try {
        this.sessionId = await RequestService.postCheckout(this.quantity);
        this.$refs.checkoutRef.redirectToCheckout();
      } catch (err) {
        if (err.message) this.makeToast(err.message, "Oh no", "danger");
      }
      this.loading = false;
    },
    increase() {
      if (this.quantity < 9999999) this.quantity++;
      this.priceCalc();
    },
    decrease() {
      if (this.quantity > 0) this.quantity--;
      this.priceCalc();
    },
    priceCalc() {
      if (this.quantity <= 50) {
        this.price = 5 * this.quantity;
      } else if (this.quantity <= 250 && this.quantity >= 51) {
        this.price = 4.5 * this.quantity;
      } else if (this.quantity <= 1250 && this.quantity >= 251) {
        this.price = 4 * this.quantity;
      } else if (this.quantity <= 5000 && this.quantity >= 1251) {
        this.price = 3.5 * this.quantity;
      } else if (this.quantity <= 10000 && this.quantity >= 5001) {
        this.price = 3 * this.quantity;
      } else if (this.quantity >= 10001) {
        this.price = 2.5 * this.quantity;
      }
    },
    makeToast(message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
