<template>
  <div>
    <b-button @click="launchCreate()" variant="success"
      ><b-icon-plus></b-icon-plus
    ></b-button>
    <b-modal
      :visible="createModal"
      title="Add A New Teacher"
      hide-footer
      @hide="closeCreate"
      body-class="position-static"
    >
      <b-form @submit.prevent="submitCreate">
        <label>Name</label>
        <b-form-input required v-model="name" />
        <label>Email</label>
        <b-form-input type="email" required v-model="email" />
        <label>Job</label>
        <b-form-input required v-model="job" />
        <label>Assigned Integrations</label>
        <b-form-checkbox-group v-model="integrations" stacked>
          <b-form-checkbox
            v-for="integration in $store.state.auth.user.integrations"
            :key="integration.id"
            :value="integration"
            >{{ integration.name }}</b-form-checkbox
          >
        </b-form-checkbox-group>
        <b-button type="submit" variant="primary">Create</b-button>
      </b-form>
      <b-overlay :show="loading" no-wrap rounded></b-overlay>
    </b-modal>
    <b-skeleton-table
      v-if="loading"
      :rows="5"
      :columns="2"
      :table-props="{ striped: true }"
    />
    <b-table v-if="!loading" striped hover :items="teachers" :fields="fields">
      <template #cell(Actions)="row">
        <b-button
          size="sm"
          variant="primary"
          @click="launchEdit(row.item, $event.target)"
          style="margin-right: 0.5rem"
        >
          <b-icon-pencil-square></b-icon-pencil-square>
        </b-button>
        <b-button
          size="sm"
          variant="primary"
          @click="launchDelete(row.item, $event.target)"
        >
          <b-icon-trash></b-icon-trash>
        </b-button>
      </template>
    </b-table>
    <b-modal
      :visible="editModal"
      title="Edit A Teacher"
      hide-footer
      @hide="closeEdit"
      body-class="position-static"
    >
      <b-form @submit.prevent="submitEdit">
        <label>Name</label>
        <b-form-input required v-model="name" />
        <label>Email</label>
        <b-form-input
          type="email"
          :disabled="resetEmail !== ''"
          required
          v-model="email"
        />
        <div class="d-flex justify-content-between">
          <div>
            <b-link :disabled="loading" v-if="resetEmail !== ''" @click="resend"
              >Resend Email</b-link
            >
          </div>
          <div>
            <b-link
              :disabled="loading"
              class="text-left"
              v-if="resetEmail !== ''"
              @click="cancel"
              >Cancel</b-link
            >
          </div>
        </div>
        <label>Job</label>
        <b-form-input required v-model="job" />
        <label>Assigned Integrations</label>
        <b-form-checkbox-group v-model="integrations" stacked>
          <b-form-checkbox
            v-for="integration in $store.state.auth.user.integrations"
            :key="integration.id"
            :value="integration"
            >{{ integration.name }}</b-form-checkbox
          >
        </b-form-checkbox-group>
        <b-button type="submit" variant="primary">Update</b-button>
      </b-form>
      <b-overlay :show="loading" no-wrap rounded></b-overlay>
    </b-modal>
    <b-modal
      :visible="deleteModal"
      title="Remove A Teacher"
      hide-footer
      @hide="closeDelete"
      body-class="position-static"
    >
      <p>Are you sure you want to delete {{ this.name }}?</p>
      <b-button @click="submitDelete" variant="primary">Delete</b-button>
      <b-overlay :show="loading" no-wrap rounded></b-overlay>
    </b-modal>
  </div>
</template>
<script>
import RequestService from "@/services/RequestService";

export default {
  name: "Teacher",
  data() {
    return {
      fields: [
        { key: "name", label: "Name", class: "text-center" },
        { key: "email", label: "Email", class: "text-center" },
        {
          key: "integrations",
          label: "Integrations",
          formatter: "csv",
          class: "text-center",
        },
        { key: "Actions", class: "text-center" },
      ],
      teachers: [],
      integrations: [],
      name: "",
      email: "",
      job: "",
      oldEmail: "",
      resetEmail: "",
      createModal: false,
      editModal: false,
      deleteModal: false,
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    await this.getTeachers();
    this.loading = false;
  },
  methods: {
    launchCreate() {
      this.createModal = true;
    },
    launchEdit(teacher) {
      this.name = teacher.name;
      this.email = teacher.email;
      this.job = teacher.job;
      this.oldEmail = teacher.oldEmail;
      if (teacher.resetEmail) {
        this.resetEmail = teacher.resetEmail;
        this.email = teacher.resetEmail;
      }
      let integrations = [];
      for (let selectedIntegration of teacher.integrations) {
        for (let integration of this.$store.state.auth.user.integrations) {
          if (selectedIntegration.id === integration.id)
            integrations.push(integration);
        }
      }
      this.integrations = integrations;
      this.editModal = true;
    },
    launchDelete(teacher) {
      this.name = teacher.name;
      this.email = teacher.email;
      this.integrations = teacher.integrations;
      this.deleteModal = true;
    },
    closeCreate() {
      this.name = "";
      this.email = "";
      this.job = "";
      this.oldEmail = "";
      this.integrations = [];
      this.createModal = false;
    },
    closeEdit() {
      this.name = "";
      this.email = "";
      this.job = "";
      this.oldEmail = "";
      this.resetEmail = "";
      this.integrations = [];
      this.editModal = false;
    },
    closeDelete() {
      this.name = "";
      this.email = "";
      this.integrations = [];
      this.deleteModal = false;
    },
    async getTeachers(timestamp = false) {
      try {
        const getTeacher = await RequestService.getTeacher(timestamp);
        this.teachers = getTeacher.teachers;
        for (let i in this.teachers) {
          this.teachers[i].oldEmail = this.teachers[i].email;
          if (this.teachers[i].resetEmail)
            this.teachers[i].email = this.teachers[i].resetEmail;
        }
      } catch (err) {
        if (err.message) this.makeToast(err.message, "Oh no", "danger");
      }
      return Promise.resolve(true);
    },
    async submitCreate() {
      this.loading = true;
      try {
        await RequestService.postTeacher(
          this.email,
          this.name,
          this.job,
          this.integrations
        );
        await this.getTeachers(true);
        this.makeToast(
          "Let " +
            this.name +
            " know to check their inbox: " +
            this.email +
            " for instructions",
          "Created Teacher",
          "success"
        );
      } catch (err) {
        if (err.message) this.makeToast(err.message, "Oh no", "danger");
      }
      this.loading = false;
      this.closeCreate();
    },
    async submitEdit() {
      this.loading = true;
      try {
        await RequestService.patchTeacher(
          this.email,
          this.name,
          this.job,
          this.integrations,
          this.oldEmail
        );
        await this.getTeachers(true);
        this.makeToast(
          this.name + " was updated successfully",
          "Teacher Updated",
          "success"
        );
      } catch (err) {
        if (err.message) this.makeToast(err.message, "Oh no", "danger");
      }
      this.loading = false;
      this.closeEdit();
    },
    async submitDelete() {
      this.loading = true;
      try {
        await RequestService.deleteTeacher(this.email, this.integrations);
        await this.getTeachers(true);
        this.makeToast(
          this.name + " was deleted successfully",
          "Teacher Deleted",
          "success"
        );
      } catch (err) {
        if (err.message) this.makeToast(err.message, "Oh no", "danger");
      }
      this.loading = false;
      this.closeDelete();
    },
    csv(value) {
      let temp = "";
      for (let s in value) {
        if (s < value.length - 1) {
          temp += value[s].name + ", ";
        } else {
          temp += value[s].name;
        }
      }
      return temp;
    },
    async cancel() {
      this.loading = true;
      try {
        await RequestService.patchTeacherCancel(this.oldEmail);
        this.closeEdit();
        await this.getTeachers(true);
        this.resetEmail = "";
      } catch (err) {
        if (err.message) this.makeToast(err.message, "Oh no", "danger");
      }
      this.loading = false;
    },
    async resend() {
      this.loading = true;
      try {
        await RequestService.patchTeacherResend(this.oldEmail);
        this.makeToast(
          "Check " + this.resetEmail + "'s inbox for instructions",
          "Email resent",
          "success"
        );
      } catch (err) {
        if (err.message) this.makeToast(err.message, "Oh no", "danger");
      }
      this.loading = false;
    },
    makeToast(message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
