<template>
  <div>
    <b-nav>
      <b-container class="mx-auto text-center" style="max-width: 22rem">
        <b-row>
          <b-col>
            <b-link to="/terms"> Terms </b-link>
          </b-col>
          <b-col>
            <b-link to="/privacy"> Privacy </b-link>
          </b-col>
          <b-col>
            <b-link to="/security"> Security </b-link>
          </b-col>
          <b-col>
            <b-link to="/contact"> Contact </b-link>
          </b-col>
        </b-row>
      </b-container>
    </b-nav>
  </div>
</template>

<script>
export default {
  name: "MiniFooter",
};
</script>
