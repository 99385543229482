<template>
  <div>
    <DashboardHeader approval="true" />
    <b-skeleton-table
      v-if="loading"
      :rows="5"
      :columns="2"
      :table-props="{ striped: true }"
    />
    <b-table v-if="!loading" striped hover :items="approvals" :fields="fields">
      <template #cell(Actions)="row">
        <b-button
          variant="primary"
          size="sm"
          @click="launchApproval(row.item, $event.target)"
          class="mr-1"
        >
          Approve
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="launchDenial(row.item, $event.target)"
          class="mr-1"
        >
          Deny
        </b-button>
      </template>
    </b-table>

    <b-modal
      :visible="approvalModal"
      title="Approve Account"
      hide-footer
      @hide="closeApproval"
      body-class="position-static"
    >
      <p>Are you sure you want to approve {{ this.name }}?</p>
      <b-button @click="submitApproval" variant="primary">Approve</b-button>
      <b-overlay :show="loading" no-wrap rounded></b-overlay>
    </b-modal>

    <b-modal
      :visible="denialModal"
      title="Deny Account"
      hide-footer
      @hide="closeDenial"
      body-class="position-static"
    >
      <p>Are you sure you want to deny {{ this.name }}?</p>
      <b-button @click="submitDenial" variant="primary">Deny</b-button>
      <b-overlay :show="loading" no-wrap rounded></b-overlay>
    </b-modal>
  </div>
</template>

<script>
import RequestService from "@/services/RequestService";
import DashboardHeader from "@/components/headers/Dashboard.vue";

export default {
  name: "Approval",
  components: {
    DashboardHeader,
  },
  data() {
    return {
      fields: [
        "name",
        "email",
        "organization",
        "organizationType",
        "job",
        "useCase",
        "Actions",
      ],
      email: "",
      name: "",
      approval: "",
      approvals: [],
      approvalModal: false,
      denialModal: false,
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    await this.getApprovals();
    this.loading = false;
  },
  methods: {
    async getApprovals(timestamp = false) {
      try {
        const getApproval = await RequestService.getApproval(timestamp);
        this.approvals = getApproval.approvals;
      } catch (err) {
        if (err.message) this.makeToast(err.message, "Oh no", "danger");
      }
    },
    launchApproval(approval) {
      this.email = approval.email;
      this.name = approval.name;
      this.approval = "Approved";
      this.approvalModal = true;
    },
    launchDenial(denial) {
      this.email = denial.email;
      this.name = denial.name;
      this.approval = "Denied";
      this.denialModal = true;
    },
    closeApproval() {
      this.email = "";
      this.name = "";
      this.approval = "";
      this.approvalModal = false;
    },
    closeDenial() {
      this.email = "";
      this.name = "";
      this.approval = "";
      this.denialModal = false;
    },
    async submitApproval() {
      this.loading = true;
      try {
        await RequestService.postApproval(this.email, this.approval);
        await this.getApprovals(true);
        this.makeToast(
          this.name + " was aproved successfully",
          "Account Approved",
          "success"
        );
      } catch (err) {
        if (err.message) this.makeToast(err.message, "Oh no", "danger");
      }
      this.loading = false;
      this.closeApproval();
    },
    async submitDenial() {
      this.loading = true;
      try {
        await RequestService.postApproval(this.email, this.approval);
        await this.getApprovals(true);
        this.makeToast(
          this.name + " was denied successfully",
          "Account Denied",
          "success"
        );
      } catch (err) {
        if (err.message) this.makeToast(err.message, "Oh no", "danger");
      }
      this.loading = false;
      this.closeDenial();
    },
    makeToast(message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
