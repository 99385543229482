<template>
  <div>
    <div class="wrapper mx-auto">
      <b-link to="/">
        <img
          class="mx-auto d-block mt-4"
          src="../assets/logo_darkblue.png"
          width="75px"
          height="75px"
      /></b-link>
    </div>
    <p class="text-center h4 mt-2">{{ typeOf }} your password</p>
    <b-card class="mx-auto mt-4" style="max-width: 22rem" bg-variant="light">
      <b-form @submit.prevent="setPass">
        <label>New password</label>
        <b-form-input
          required
          :state="minLen"
          v-model="password"
          type="password"
        />
        <b-form-invalid-feedback :state="minLen">
          Password must be at least 8 characters
        </b-form-invalid-feedback>
        <label class="mt-3">Confirm new password</label>
        <b-form-input
          required
          v-model="confirmPassword"
          type="password"
          :state="match"
        />
        <b-form-invalid-feedback :state="match">
          Password must match
        </b-form-invalid-feedback>
        <b-button
          type="submit"
          style="width: 100%"
          class="btn-block mt-4"
          variant="success"
          >Update password</b-button
        >
      </b-form>
      <b-overlay :show="loading" no-wrap></b-overlay>
    </b-card>

    <MiniFooter class="my-5" />
  </div>
</template>

<script>
import MiniFooter from "@/components/footers/Mini.vue";
import RequestService from "@/services/RequestService";

export default {
  name: "PasswordSet",
  components: {
    MiniFooter,
  },
  data() {
    return {
      email: "",
      resetPassToken: "",
      password: "",
      confirmPassword: "",
      loading: false,
      typeOf: "Update",
    };
  },
  created() {
    this.email = this.$route.query.email;
    this.resetPassToken = this.$route.query.token;
    if (this.$route.query.firstTime) this.typeOf = "Set";
  },
  computed: {
    minLen() {
      if (this.password.length === 0) return null;
      else return this.password.length >= 8;
    },
    match() {
      if (this.confirmPassword.length === 0) return null;
      else return this.confirmPassword === this.password;
    },
  },
  methods: {
    async setPass() {
      this.loading = true;
      try {
        await RequestService.postPasswordSet(
          this.password,
          this.confirmPassword,
          this.email,
          this.resetPassToken
        );
        this.$router.push("/login?passwordUpdate=true");
      } catch (err) {
        if (err.message) this.makeToast(err.message, "Oh no", "danger");
      }
      this.loading = false;
    },
    makeToast(message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
