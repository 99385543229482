<template>
  <div id="body">
    <!-- Landing panel -->
    <div id="heroSpacer">
      <div class="anchor" id="start"></div>
      <img
        src="../../assets/shield.png"
        id="mobileShield"
        class="mx-auto"
        alt="Green shield with lock upon it"
      />
      <b-row class="mx-auto">
        <b-col sm="7" class="mx-auto mb-5" id="heroText">
          <h1 class="scAccent" style="font-size: 40px">
            The First Security Awareness Program<br />
            For Students
          </h1>
          <p style="font-size: 20px">
            SaferCloud provides <b>interactive</b> and <b>rewarding</b> training
            to prepare students to be mindful and safe on the internet.
          </p>

          <br />
          <b-button size="lg" variant="primary" pill href="#information"
            >More Info</b-button
          >
          <b-button
            class="ml-3"
            size="lg"
            variant="outline-secondary"
            pill
            href="#contact"
            >Contact us</b-button
          >
        </b-col>

        <b-col sm="4" class="mx-auto" id="shield">
          <img
            src="../../assets/shield.png"
            class="mt-3 ml-auto"
            width="100%"
            alt="Green shield with lock upon it"
          />
        </b-col>
      </b-row>
    </div>

    <!-- Information panels -->
    <b-container fluid class="py-5 highlighted">
      <div class="anchor" id="information"></div>
      <!-- Student Protection -->
      <b-row>
        <b-col sm class="d-flex">
          <b-card class="border-0 mx-auto mb-3" style="max-width: 750px">
            <b-row class="no-gutters">
              <b-col md="3">
                <img
                  class="card-img mt-3 flex-fill infoImg"
                  src="../../assets/student.png"
                  alt="Student in uniform with black hair"
                />
              </b-col>
              <b-col md="9">
                <b-card-body class="flex-fill">
                  <b-card-title>
                    <b>Protect Your Students</b>
                  </b-card-title>
                  <b-card-text>
                    Students are rapidly becoming the next attack target. The
                    U.S. Department of Education has recognized that students
                    face increasing virtual threats. However, many schools do
                    not have adequate online security training programs.
                    SaferCloud fills this gap, providing security awareness
                    designed specifically for students.
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <!-- Value -->
      <b-row>
        <b-col sm class="d-flex">
          <b-card class="border-0 mx-auto mb-3" style="max-width: 750px">
            <b-row class="no-gutters">
              <b-col md="3">
                <img
                  class="card-img mt-3 flex-fill infoImg"
                  src="../../assets/identity.png"
                  alt="Identity card with photo"
                />
              </b-col>
              <b-col md="9">
                <b-card-body class="flex-fill">
                  <b-card-title>
                    <b>More Than Just Security</b>
                  </b-card-title>
                  <b-card-text>
                    SaferCloud teaches about much more than cyber attacks. The
                    training also teaches students about the importance of their
                    internet privacy, how to avoid cyber bullies and predators,
                    and more. This is especially important in the age of remote
                    learning.
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <!-- Interactive -->
      <b-row>
        <b-col sm class="d-flex">
          <b-card class="border-0 mx-auto mb-3" style="max-width: 750px">
            <b-row class="no-gutters">
              <b-col md="3">
                <img
                  class="card-img mt-3 flex-fill infoImg"
                  src="../../assets/reward.png"
                  alt="Trophy with star and red ribbons"
                />
              </b-col>
              <b-col md="9">
                <b-card-body class="flex-fill">
                  <b-card-title>
                    <b>Interactive and Rewarding</b>
                  </b-card-title>
                  <b-card-text>
                    SaferCloud is designed with younger generations in mind. The
                    training is focused around a reward system that encourages
                    good results. This keeps students entertained and focused
                    which allows them to retain important concepts.
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <!-- Campus Security -->
      <b-row>
        <b-col sm class="d-flex">
          <b-card class="border-0 mx-auto mb-3" style="max-width: 750px">
            <b-row class="no-gutters">
              <b-col md="3">
                <img
                  class="card-img mt-3 flex-fill infoImg"
                  src="../../assets/school.png"
                  alt="School with clock and red flag"
                />
              </b-col>
              <b-col md="9">
                <b-card-body class="flex-fill">
                  <b-card-title>
                    <b>Improve Campus Security</b>
                  </b-card-title>
                  <b-card-text>
                    Students and their personal devices increase the attack
                    surface of your network. Providing students with a strong
                    foundation in online security can greatly reduce risks
                    coming from inside your campus community.
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <!-- Integration -->
      <b-row>
        <b-col sm class="d-flex">
          <b-card class="border-0 mx-auto mb-3" style="max-width: 750px">
            <b-row class="no-gutters">
              <b-col md="3">
                <img
                  class="card-img mt-3 flex-fill infoImg"
                  src="../../assets/lms.png"
                  alt="Tablet with reading information"
                />
              </b-col>
              <b-col md="9">
                <b-card-body class="flex-fill">
                  <b-card-title>
                    <b>Easy Integration</b>
                  </b-card-title>
                  <b-card-text>
                    SaferCloud integrates out-of-the box with modern learning
                    platforms such as Blackboard, Canvas, and Moodle using LTI
                    technology. We use the LTI Deep Linking standard to ensure
                    that teachers can easily assign, manage, and track student
                    progress across learning modules.
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid class="py-5">
      <div class="anchor" id="learn"></div>
      <b-row>
        <b-col sm class="d-flex">
          <b-card class="border-0 mx-auto" style="max-width: 750px">
            <b-card-body class="flex-fill">
              <b-card-title>
                <b>Learning Modules</b>
              </b-card-title>
              <b-card-text>
                <p>
                  SaferCloud encompasses a set of learning modules presented as
                  games, quizzes, and videos. Each topic has modules that fit
                  your students&#39; demographics.
                </p>
                <h5 class="mt-4">Learning Modules Cover Topics Including:</h5>
                <ul style="line-height: 28px">
                  <li>Security Responsibility</li>
                  <li>Social Engineering</li>
                  <li>Safe Web Browsing</li>
                  <li>Cyber Security Defenses</li>
                  <li>Online Reputation</li>
                  <li>Online Privacy</li>
                  <li>Security Responses</li>
                  <li>And many more...</li>
                </ul>
              </b-card-text>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <!-- Contact -->
    <b-container fluid class="py-5 highlighted">
      <div class="anchor" id="contact"></div>
      <b-row>
        <b-col sm class="d-flex">
          <b-card class="border-0 mx-auto" style="max-width: 750px">
            <b-card-body class="flex-fill">
              <b-card-title class="card-title">
                <b>Contact</b>
              </b-card-title>
              <b-card-text class="card-text">
                Have any questions or feedback? We&#39;d love to talk. Send us
                an email at
                <b-link href="mailto:contact@safercloud.io"
                  >contact@safercloud.io</b-link
                >
                and we&#39;ll do our best to get back to you as quickly as
                possible.
              </b-card-text>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="d-flex">
          <b-card class="border-0 mx-auto" style="max-width: 250px">
            <b-button pill variant="outline-primary" to="/register"
              >Register for an account</b-button
            >
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  name: "LandingContent",
};
</script>
<style>
.scAccent {
  color: #084075 !important;
}

#mobileShield {
  display: none;
}

#about {
  font-size: 17px;
}

.card {
  background-color: rgba(0, 0, 0, 0) !important;
}

.highlighted {
  background-color: rgba(0, 0, 0, 0.05);
}

.anchor {
  position: absolute;
  margin-top: -110px;
}

@media only screen and (min-width: 776px) {
  #heroSpacer {
    box-sizing: border-box;
    padding-top: 25vh;
    height: 90vh;
    max-width: 750px;
    margin: 0 auto;
  }

  .infoImg {
    max-width: 135px;
    margin-right: 15px;
  }
}

@media only screen and (max-width: 775px) {
  .infoImg {
    max-width: 140px !important;
    display: block;
    margin: 0 auto;
  }

  #heroText {
    text-align: center;
  }

  #shield {
    display: none;
  }

  #mobileShield {
    display: block;
    max-width: 180px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
</style>
